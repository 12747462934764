import { setDate } from "date-fns";
import React, { useEffect, useState } from "react";
import { modifyHour } from "../../api/apiAdmin";
import { askTime } from "../../api/apiClient";
const options = { year: "numeric", month: "2-digit", day: "2-digit" };
function TimeIntervals({ date, refreshTime }) {
  const initialValues = {
    "13:00": false,
    "13:30": false,
    "14:00": false,
    "14:30": false,
    "15:00": false,
    "15:30": false,
    "19:30": false,
    "20:00": false,
    "20:30": false,
    "21:00": false,
    "21:30": false,
    "22:00": false,
    "22:30": false,
  };

  const [intervalsAvailable, setIntervalsAvailable] = useState(initialValues);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setIntervalsAvailable(initialValues);
    availableTimes();
  }, [date, refreshTime]);

  const availableTimes = async () => {
    setLoaded(false);
    const res = await askTime(date, -1);
    res.map((e) => {
      const time = new Date(e).toLocaleTimeString("es-SP").slice(0, -3);
      setIntervalsAvailable((previousState) => ({
        ...previousState,
        [time]: true,
      }));
    });
    // console.log(intervalsAvailable);
    setLoaded(true);
  };

  const changeAvailability = async (key, value) => {
    const dateFormated = date.toLocaleDateString("zh-CN", options);
    await modifyHour(dateFormated, key, !value);
    setIntervalsAvailable((previousState) => ({
      ...previousState,
      [key]: !value,
    }));
  };
  const timeMap = (condition) => {
    return Object.entries(intervalsAvailable).map(([key, value]) => {
      const cond = condition ? key.split(0, 1) < "17" : key.split(0, 1) > "17";
      return (
        <>
          {cond && (
            <button
              className={` ${
                value
                  ? "bg-green-300 border-green-900 "
                  : "bg-red-300 border-red-900 "
              }  border-2 rounded-lg text-black w-2/5 py-1 text-xs `}
              onClick={() => changeAvailability(key, value)}
              key={key}
            >
              {key}
            </button>
          )}
        </>
      );
    });
  };

  return (
    <div className="h-fit flex flex-col gap-2">
      {loaded && (
        <>
          <div className="flex flex-col gap-2">
            <div className="text-white text-xs">早上</div>
            <div className="flex  flex-wrap gap-2 justify-center">
              {timeMap(true)}
            </div>
          </div>
          <div className="flex flex-col gap-2 ">
            <div className="text-white text-xs ">晚上</div>
            <div className="flex  flex-wrap  justify-start gap-2 justify-center">
              {timeMap(false)}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default TimeIntervals;

import React from "react";

function Verification({ data, setConfirmation, setShow }) {
  const { name, comments, email, numP, tel, date, time } = data;

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const optionTime = { hour: "2-digit", minute: "2-digit" };

  return (
    <div className="border-2 p-8 lg:w-2/3 m-auto">
      <div className="leading-loose">
        <p className="font-bold text-lg">Hola {name} ,</p>
        <br />
        <p>
          La reserva es para{" "}
          <b>
            {numP} {numP === 1 ? "persona" : "personas"},{" "}
          </b>
          el <b>{date.toLocaleDateString("es-SP", options)}</b> a las{" "}
          <b>{time.toLocaleTimeString("es-SP", optionTime)}</b>
          h . <br />
          <br />
          El correo al que quiere recibir la confirmación es <b>{email}</b>{" "}
          <br />
          <br />Y el teléfono de contacto es <b>{tel}</b>
          <br /> Commentarios para nosotros: {comments ? comments : "-"}
        </p>
        <br />
        <p>¿Correcto?</p>
      </div>
      <div className="flex gap-4 justify-end">
        <button
          className="border-2 p-2 rounded-xl border-red-300"
          onClick={() => setShow(false)}
        >
          NO
        </button>
        <button
          className="border-2 p-2 px-3 rounded-xl border-green-300"
          onClick={() => {
            setConfirmation(true);
            setShow(false);
          }}
        >
          SI
        </button>
      </div>
    </div>
  );
}

export default Verification;

import Calendar from "./datePicker";
import { sendForm } from "../api/apiClient.js";
import React, { useState, useReducer, useEffect } from "react";
import Verification from "./verification";
import { FiEdit, FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
const errorMsg = {
  name: {
    state: false,
    msg: "Introduzca su nombre",
    pattern: /[a-zA-Z ]/g,
  },
  surname: {
    state: false,
    msg: "Introduzca su apellido",
    pattern: /[a-zA-Z ]/g,
  },
  email: {
    state: false,
    msg: "Formato de correo inválido  abcdef@abc.de",
    pattern: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$/g,
  },
  tel: {
    state: false,
    msg: "Mínimo 9 digitos - Máximo 12",
    pattern: /[0-9]{9,12}$/g,
  },
  numP: { state: false, msg: "Mínimo 1 - Máximo 18" },
  date: { state: false, msg: "Seleccione una fecha válida" },
};
function ReservationForm() {
  const [verified, setVerified] = useState(false);
  const [show, setShow] = useState(false);
  let navigate = useNavigate();
  const [autoModifyHour, setAutoModifyHour] = useState(true);
  const [submited, setSubmited] = useState(false);

  const formReducer = (state, event) => {
    return {
      ...state,
      [event.name]: event.value,
    };
  };

  const [formData, setFormData] = useReducer(formReducer, {
    name: "",
    surname: "",
    email: "",
    tel: "",
    date: new Date(),
    time: "",
    numP: "",
    comments: "",
  });

  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
    errorMsg[event.target.name].state = false;
  };

  const validateData = () => {
    var validation = true;
    for (const [key, value] of Object.entries(formData)) {
      if (key === "time") {
        if (value === undefined || value === -1) {
          validation = false;
          errorMsg.date.state = true;
        }
      } else {
        // console.log(key);
        if (key !== "date" && key !== "comments") {
          if (key !== "numP") {
            const patternMatch = value.match(errorMsg[key].pattern);

            if (
              value === "" ||
              patternMatch === null ||
              (patternMatch.length !== value.length &&
                patternMatch[0] !== value)
            ) {
              errorMsg[key].state = true;
              validation = false;
            }
          } else {
            if (value > 18 || value < 1) {
              errorMsg[key].state = true;
              validation = false;
              // console.log("number error");
            }
          }
        }
      }
    }
    setSubmited(!submited);

    return validation;
  };

  useEffect(
    async () => {
      if (verified === true) {
        await correctConfirmation();
      }
    },
    [verified],
    () => {},
    [submited]
  );

  const correctConfirmation = async () => {
    // console.log("correct confirmation, let's send the form");
    try {
      const response = await sendForm(formData);
      const id = response.data.reservationID;
      // console.log("form send status", response.status);
      if (response.status === 200) {
        navigate("/confirmation", { state: { Id: id } });
      }
    } catch (err) {
      navigate("/controlMsg", {
        state: { status: "FAIL", msg: err.response.data },
      });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // event.stopPropagation();
    const res = validateData();
    if (res === true) {
      // valid
      setAutoModifyHour(false);
      setShow(true); // show alert
    }

    console.log(errorMsg);
  };

  return (
    <>
      {show ? (
        <Verification
          data={formData}
          setConfirmation={setVerified}
          setShow={setShow}
        />
      ) : (
        <>
          <div className="flex justify-end py-4">
            <button
              className="flex gap-2 items-center "
              onClick={() => navigate("/modifyReservation")}
            >
              <FiEdit />
              <span>Modificar mi reserva</span>
            </button>
          </div>

          <form
            className="border rounded-xl p-7 flex flex-col gap-8 "
            noValidate
            autoComplete="on"
          >
            <div className="flex flex-col gap-2 lg:flex-row lg:gap-8 ">
              <div className="flex flex-col lg:w-1/2 ">
                <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
                  <label forhtml="name">Nombre</label>
                  <input
                    className="border-2 p-1 rounded-xl grow  px-2  "
                    name="name"
                    type="text"
                    value={formData["name"]}
                    onChange={handleChange}
                    autoFocus
                    required
                  />
                </div>
                <div
                  className={
                    errorMsg["name"].state
                      ? "visible p-2 flex justify-end border-2 border-red-400 text-red-600 rounded-xl gap-2 items-center text-xs mt-2 mb-2"
                      : "hidden"
                  }
                >
                  <span>{errorMsg["name"].msg}</span>
                </div>
              </div>
              <div className="flex flex-col lg:w-1/2 ">
                <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
                  <label forhtml="surname">Apellidos</label>
                  <input
                    className="border-2 p-1 rounded-xl grow px-2 "
                    name="surname"
                    type="text"
                    value={formData["surname"]}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div
                  className={
                    errorMsg.surname.state
                      ? "visible p-2 flex justify-end border-2 border-red-400 text-red-600 rounded-xl gap-2 items-center text-xs mt-2 mb-2"
                      : "hidden"
                  }
                >
                  <span>{errorMsg["surname"].msg}</span>
                </div>
              </div>
            </div>
            <div className="flex  flex-col ">
              <div className="flex  flex-col lg:flex-row gap-4 lg:items-center">
                <label>Correo electrónico</label>
                <input
                  className="border-2 p-1 rounded-xl grow px-2 "
                  name="email"
                  type="text"
                  value={formData["email"]}
                  placeholder="ejemplo@correo.es"
                  // pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
                  onChange={handleChange}
                  required
                />
              </div>

              <div
                className={
                  errorMsg.email.state
                    ? "visible p-2 flex justify-end border-2 border-red-400 text-red-600 rounded-xl gap-2 items-center text-xs mt-2 mb-2"
                    : "hidden"
                }
              >
                <span>{errorMsg.email.msg}</span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row lg:gap-8">
              <div className=" flex flex-col gap-4 lg:gap-2 lg:w-1/4">
                <label>Dia y hora </label>
                <Calendar
                  startDate={formData["date"]}
                  startTime={formData["time"]}
                  setStartDate={(date) => {
                    setFormData({
                      name: "date",
                      value: date,
                    });
                    errorMsg.date.state = false;
                  }}
                  setStartTime={(date) =>
                    setFormData({
                      name: "time",
                      value: date,
                    })
                  }
                  autoSetHour={autoModifyHour}
                  modifyHour={false}
                />
                <div
                  className={
                    errorMsg.date.state
                      ? "visible p-2 flex justify-end border-2 border-red-400 text-red-600 rounded-xl gap-2 items-center text-xs mt-2 mb-2"
                      : "hidden"
                  }
                >
                  <span>{errorMsg.date.msg} </span>
                </div>
              </div>
              <div className="flex flex-col lg:w-3/4 gap-8 lg:justify-around">
                <div className="flex flex-col lg:flex-row  w-full max-w-full gap-8 ">
                  <div className="flex   flex-col  w-3/5 max-w-3/5 ">
                    <div className="flex   flex-col lg:flex-row gap-4 lg:items-center">
                      <label forhtml="tel">Teléfono</label>
                      <input
                        className="border-2 p-1 rounded-xl grow px-2 "
                        type="text"
                        value={formData["tel"]}
                        // minLength={9}
                        // maxLength={12}
                        name="tel"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div
                      className={
                        errorMsg.tel.state
                          ? "visible p-2 flex justify-end border-2 border-red-400 text-red-600 rounded-xl gap-2 items-center text-xs mt-2 mb-2"
                          : "hidden"
                      }
                    >
                      <span>{errorMsg.tel.msg}</span>
                    </div>
                  </div>
                  <div className="flex  flex-col  w-2/5 max-w-2/5 ">
                    <div className="flex flex-col lg:flex-row gap-4 lg:items-center">
                      <label forhtml="numP" className="max-w-2/5">
                        Personas
                      </label>
                      <input
                        className="border-2 w-3/5 max-w-3/5 p-1 rounded-xl grow px-2 "
                        type="number"
                        // min={1}
                        // max={18}
                        value={formData["numP"]}
                        name="numP"
                        placeholder="1-18"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div
                      className={
                        errorMsg.numP.state
                          ? "visible p-2 flex justify-end border-2 border-red-400 text-red-600 rounded-xl gap-2 items-center text-xs mt-2 mb-2"
                          : "hidden"
                      }
                    >
                      <span>{errorMsg.numP.msg}</span>
                    </div>
                  </div>
                </div>
                <div className="flex  flex-col gap-4">
                  <label forhtml="comments">Observaciones</label>
                  <textarea
                    className="border-2 p-1 rounded-xl px-2 "
                    rows="3"
                    value={formData["comments"]}
                    placeholder="Añade algún comentario que sea relevante para nosotros"
                    name="comments"
                    maxLength="80"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                className="flex gap-2 items-center"
                type="submit"
                onClick={(event) => handleSubmit(event)}
              >
                <FiSend />
                ENVIAR
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
}

export default ReservationForm;

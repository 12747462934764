import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./../css/carousel.css";

function Carousel1(props) {
  return (
    <Carousel showStatus={false}>
      {props.imgArray.map((item) => (
        <div key={item.img}>
          <img
            src={item.img}
            key={item.img}
            alt={"Img: " + item.description}
          ></img>
          <p className="legend"> {item.description}</p>
        </div>
      ))}
    </Carousel>
  );
}

export default Carousel1;

import React, { useEffect, useState } from "react";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { fetchReservations } from "../../api/apiAdmin";

import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import IndividualReservation from "./individualReservation";

function ReservationsAdmin({
  startDate,
  setStartDate,
  refreshTime,
  setRefreshTime,
}) {
  const [reservations, setReservations] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [refreshReserv, setRefreshReserv] = useState(false);
  const [lastLeft, setLastLeft] = useState(true);
  const [lastRight, setLastRight] = useState(false);
  useEffect(async () => {
    if (startDate.getDate() === new Date().getDate()) {
      setLastLeft(true);
    } else {
      setLastLeft(false);
    }
    if (startDate.getDate() === addDays(new Date(), 14).getDate()) {
      setLastRight(true);
    } else {
      setLastRight(false);
    }
    setLoaded(false);
    const reservations = await fetchReservations(startDate);
    setReservations(reservations);
    setLoaded(true);
  }, [startDate, refreshReserv]);

  const options = { year: "numeric", month: "long", day: "numeric" };
  return (
    <>
      <div className=" justify-around flex  basis-1/12">
        <button
          className={` ${lastLeft ? "invisible" : "visible"}`}
          onClick={() => setStartDate(startDate.addDays(-1))}
        >
          <IoIosArrowDropleft className="w-8 h-8" />
        </button>
        <p className="self-center font-bold text-xl">
          {startDate.toLocaleDateString("zh-CN", options)}
        </p>
        <button
          className={` ${lastRight ? "invisible" : "visible"}`}
          onClick={() => setStartDate(startDate.addDays(1))}
        >
          <IoIosArrowDropright className="w-8 h-8" />
        </button>
      </div>
      {console.log(reservations.length)}
      {loaded &&
        (reservations.length !== 0 ? (
          <div className="flex flex-col gap-3  pb-8  px-4 lg:w-4/5 lg:m-auto overflow-y-auto basis-11/12">
            {reservations.map((element) =>
              element["reservations"].map((reserv) => (
                <IndividualReservation
                  key={reserv["ReservationID"]}
                  reserv={reserv}
                  date={startDate}
                  hour={element["hour"]}
                  refreshReserv={refreshReserv}
                  setRefreshReserv={setRefreshReserv}
                  refreshTime={refreshTime}
                  setRefreshTime={setRefreshTime}
                />
              ))
            )}
          </div>
        ) : (
          <div className="self-center">还没人订桌哦！</div>
        ))}
    </>
  );
}
export default ReservationsAdmin;

import { useState } from "react";
import { FiXCircle, FiSave } from "react-icons/fi";
import Calendar from "./../datePicker";
import { modifyReservation } from "../../api/apiClient";
const options = { year: "numeric", month: "2-digit", day: "2-digit" };
function EditReservation({ setEdit, editData, refresh, setRefresh }) {
  const [date, setDate] = useState(new Date(editData["DateReservation"]));
  const timeHelperDate = new Date().toLocaleDateString("zh-CN").split("/");
  const timeHelperTime = editData["HourReservation"].split(":");
  const [changedForm, setChangedForm] = useState(editData);

  const [msg, setMsg] = useState(false);
  const [time, setTime] = useState(
    new Date(
      timeHelperDate[0],
      timeHelperDate[1] - 1,
      timeHelperDate[2],
      timeHelperTime[0],
      timeHelperTime[1],
      0
    )
  );
  // const refreshPage = () => {
  //   window.location.reload(false);
  // };
  const handleConfirmEdit = async () => {
    setEdit(false);

    await modifyReservation({
      ...changedForm,
      newDate: date.toLocaleDateString("zh-CN", options),
      newTime: time.toLocaleTimeString("sp-SP").slice(0, 5),
    });
    setRefresh(!refresh);
  };
  const changeEditData = (event) => {
    setChangedForm((previousState) => ({
      ...previousState,
      [event.target.name]: event.target.value,
    }));
  };
  const handleSendEdit = () => {
    setMsg(true);
  };
  const handleCloseEdit = () => {
    setEdit(false);
  };

  return (
    <>
      <div className=" flex flex-col w-full border-2 px-4 py-2 rounded-lg ">
        <div className=" flex py-2 ">
          <p className="mb-0 font-bold text-lg grow">
            更改预约 {changedForm["ReservationID"]}{" "}
          </p>
          <button onClick={handleCloseEdit}>
            <FiXCircle className="w-8 pr-2" />
          </button>
        </div>

        <form className="flex flex-col gap-2  ">
          <div className="flex lg:flex-row flex-col gap-4">
            <div className=" flex flex-col lg:w-1/2">
              <label className="font-bold pl-1 pb-1" forhtml="Name">
                名字
              </label>
              <input
                className="border-2 w-full rounded-lg px-2"
                type="text"
                name="Name"
                value={changedForm["Name"]}
                readOnly
              />
            </div>
            <div className="flex flex-col lg:w-1/2">
              <label className="font-bold pl-1 pb-1" forhtml="Surname">
                姓氏
              </label>
              <input
                className="border-2  w-full rounded-lg px-2"
                type="text"
                name="Surname"
                value={changedForm["Surname"]}
                readOnly
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-4">
            <div className="flex flex-col lg:w-1/2">
              <div className=" flex flex-col">
                <label className="font-bold pl-1 pb-1 " forhtml="Email">
                  邮箱
                </label>
                <input
                  className="border-2  w-full rounded-lg px-2"
                  type="email"
                  name="Email"
                  value={changedForm["Email"]}
                  readOnly
                />
              </div>
              <div className="flex flex-col ">
                <p className="font-bold py-2">时间</p>
                <Calendar
                  setStartDate={setDate}
                  setStartTime={setTime}
                  startDate={date}
                  startTime={time}
                  autoSetHour={false}
                  modifyHour={true}
                />
              </div>
            </div>

            <div className="flex flex-col lg:w-1/2 gap-2 justify-around">
              <div className=" flex flex-col">
                <label className="font-bold pl-1 pb-1 " forhtml="NumPeople">
                  人数
                </label>

                <input
                  className="border-2  w-full rounded-lg px-2"
                  type="number"
                  name="NumPoeple"
                  onChange={(event) => changeEditData(event)}
                  value={changedForm["NumPeople"]}
                />
              </div>
              <div className=" flex flex-col">
                <label className="font-bold pl-1 pb-1" forhtml="Phone">
                  电话号码
                </label>

                <input
                  className="border-2  w-full rounded-lg px-2"
                  type="tel"
                  name="Phone"
                  onChange={(event) => changeEditData(event)}
                  value={changedForm["Phone"]}
                />
              </div>
              <div className="flex flex-col  ">
                <label className="font-bold pl-1 pb-1" forhtml="Comments">
                  备注
                </label>
                <textarea
                  className="border-2 w-full rounded-lg px-2"
                  rows={4}
                  type="text"
                  name="Comments"
                  maxLength="80"
                  onChange={(event) => changeEditData(event)}
                  value={changedForm["Comments"]}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="self-end pr-2 ">
          <button
            className=" flex items-center p-2 "
            onClick={() => {
              handleSendEdit();
            }}
          >
            <FiSave />
            <span className="pl-1">保存</span>
          </button>
        </div>
      </div>
      {msg && (
        <div className=" flex px-4 p-2 border-yellow-600 font-bold text-slate-800 rounded-lg bg-slate-300 justify-between ">
          <span>确定更改？</span>
          <div className="flex gap-8">
            <button className="text-green-600" onClick={handleConfirmEdit}>
              确定
            </button>
            <button className="text-red-700" onClick={handleCloseEdit}>
              取消
            </button>
          </div>
        </div>
      )}
    </>
  );
}
export default EditReservation;

import { BiMapPin, BiPhoneCall } from "react-icons/bi";
import { FiMail, FiSun, FiMoon } from "react-icons/fi";
import React from "react";

function Contact() {
  return (
    <div className=" w-4/5 lg:w-3/4 flex flex-col gap-8 border-2 p-7 rounded-xl my-5 place-self-center">
      <div className=" font-bold text-xl">¿Dónde encontrarnos?</div>
      <div className="flex flex-col gap-4 ">
        <span className="text-lg font-bold">Localización</span>
        <div className="flex gap-4 items-center">
          <BiMapPin className="shrink-0" />
          <a
            href="http://maps.google.com/?q=08010 Gran Via de les Corts Catalanes, 692, Bajo 2, 
            Barcelona, Spain"
          >
            Gran Via de les Corts Catalanes, 692, Bajo 2, Barcelona, Spain
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <span className="text-lg font-bold"> Teléfono de contacto</span>
        <div className="flex gap-4 items-center">
          <BiPhoneCall />
          <a href="tel:+34 930 31 64 02">+34 930 31 64 02</a>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <span className="text-lg font-bold">Correo electrónico</span>
        <div className="flex gap-4 items-center">
          <FiMail />
          <a href="mailto:slxhotpotbcn@gmail.com">slxhotpotbcn@gmail.com</a>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <span className="text-lg font-bold">Horario</span>
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="flex gap-4 items-center">
            <FiSun />
            <span> 13:00h-16:30h</span>
          </div>
          <div className="flex gap-4 items-center">
            <FiMoon />
            <span>19:00h-23:30h</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

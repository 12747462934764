import TimeIntervals from "./timeIntervals";
import DatePicker from "react-datepicker";
import "./../../css/datepickerAdmin.css";
import logo from "../img/shuler_logo_white.png";
import zh from "date-fns/locale/zh-CN";
import { addDays } from "date-fns";

function NavbarAdmin({ startDate, setStartDate, refreshTime }) {
  return (
    <div className="flex flex-col p-3 lg:h-screen max-h-fit">
      <div className="flex place-self-center h-10">
        <img className="h-full " src={logo} alt={"Img logo"} />
      </div>
      <div className="place-self-center p-4  h-30 lg:block hidden">
        <DatePicker
          className="bg-black m-auto"
          selected={startDate}
          dateFormat="yyyy MM d"
          minDate={new Date()}
          maxDate={addDays(new Date(), 14)}
          locale={zh}
          onChange={(date) => setStartDate(date)}
          inline
        />
      </div>
      <div className="lg:h-60 max-h-fit">
        <TimeIntervals date={startDate} refreshTime={refreshTime} />
      </div>
    </div>
  );
}
export default NavbarAdmin;

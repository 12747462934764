import React from "react";
import menu from "./img/menu.jpg";
function Menu() {
  return (
    <div>
      <img src={menu} className="w-100" />
    </div>
  );
}

export default Menu;

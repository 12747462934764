import ReservationsAdmin from "./reservationsAdmin";
import NavbarAdmin from "./navbarAdmin";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { askPermission } from "../../api/apiAdmin";

function AdminMain() {
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("refreshed");
      refreshPage();
    }, 240000); //render every 4 minutes;
    return () => clearInterval(interval);
  }, []);
  const refreshPage = () => {
    window.location.reload(false);
  };
  const nav = useNavigate();
  const [allow, setAllow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [refreshTime, setRefreshTime] = useState(false);
  useEffect(async () => {
    try {
      const res = await askPermission();
      if (res === 200) {
        setAllow(true);
      }
    } catch (err) {
      nav("/login");
    }
  });
  return (
    <>
      {allow ? (
        <div className="flex lg:flex-row  flex-col  lg:max-h-screen overflow-hidden lg:h-screen ">
          <div className="lg:w-1/4 bg-black lg:max-h-screen lg:h-screen ">
            <NavbarAdmin
              startDate={startDate}
              setStartDate={(date) => setStartDate(date)}
              refreshTime={refreshTime}
            />
          </div>
          <div className="lg:w-3/4 lg:max-h-screen lg:h-screen  relative flex flex-col">
            <ReservationsAdmin
              startDate={startDate}
              setStartDate={(date) => setStartDate(date)}
              refreshTime={refreshTime}
              setRefreshTime={setRefreshTime}
            />
          </div>
        </div>
      ) : (
        "LOADING"
      )}
    </>
  );
}
export default AdminMain;

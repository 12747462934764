import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { sendLogin } from "../api/apiClient.js";
import { FiSend } from "react-icons/fi";
import { sha256 } from "js-sha256";
function Login() {
  const nav = useNavigate();
  const [user, setUser] = useState("");
  const [msg, setMsg] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await sendLogin(user);
      if (res.status !== 200) {
        nav("/login", { replace: true });
        setMsg(true);
      } else {
        nav("/admin", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (event) => {
    setMsg(false);
    setUser((prevState) => ({
      ...prevState,
      [event.target.name]: sha256(event.target.value),
    }));
  };
  return (
    <div className=" w-4/5 lg:w-2/5  my-5  border-2  rounded-xl place-self-center">
      <div className="pt-8 pl-8 font-bold text-xl">登录</div>
      <form className="flex flex-col p-8 gap-4" onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <label>Username: </label>
          <input
            className="border-2 p-1 rounded-xl "
            type="text"
            id="username"
            name="username"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <label>Password: </label>
          <input
            className="border-2 p-1 rounded-xl "
            type="password"
            id="pwd"
            name="pwd"
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-end">
          <button className="flex items-center gap-2" type="submit">
            <FiSend /> <span>发送</span>
          </button>
        </div>
      </form>
      {msg && (
        <div className="p-3 flex justify-end border-2 border-red-400 text-red-600 rounded-b-xl gap-2 items-center">
          账号或密码不对，请重试!
        </div>
      )}
    </div>
  );
}
export default Login;

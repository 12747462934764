import {
  FiMessageCircle,
  FiPhone,
  FiUser,
  FiTrash2,
  FiEdit,
  FiClock,
  FiPlusCircle,
  FiMail,
} from "react-icons/fi";
import { useState } from "react";
import { deleteReservation } from "../../api/apiClient";
import EditReservation from "./editReservation";
const options = { year: "numeric", month: "2-digit", day: "2-digit" };
function IndividualReservation({
  date,
  hour,
  reserv,
  refreshReserv,
  setRefreshReserv,
  refreshTime,
  setRefreshTime,
}) {
  const [editData, setEditData] = useState();
  const [edit, setEdit] = useState(false);
  const [msg, setMsg] = useState(false);
  const handleConfirmDelete = async () => {
    const dayFormated = date.toLocaleDateString("zh-CN", options);
    const res = await deleteReservation(
      reserv["ReservationID"],
      dayFormated,
      hour
    );
    setMsg(false);
    setRefreshReserv(!refreshReserv);
    setRefreshTime(!refreshTime);
    // if (res.status == 200) {
    //   console.log("reservation delete ok");
    // } else {
    //   console.log("reservation delete failed");
    // }
  };

  const handleEdit = () => {
    setEditData({
      DateReservation: date,
      HourReservation: hour,
      ReservationID: reserv["ReservationID"],
      Name: reserv["Name"],
      Surname: reserv["Surname"],
      NumPeople: reserv["NumPeople"],
      Phone: reserv["Phone"],
      Comments: reserv["Comments"],
      Email: reserv["Email"],
    });
    setEdit(true);
  };
  const handleExtra = (event) => {
    if (event.currentTarget.parentNode.nextSibling.className === "hidden") {
      event.currentTarget.parentNode.nextSibling.className =
        "show grid-row col-start-1 col-end-7 ";
    } else {
      event.currentTarget.parentNode.nextSibling.className = "hidden";
    }
  };
  return (
    <>
      {edit ? (
        <EditReservation
          setEdit={setEdit}
          editData={editData}
          refresh={refreshReserv}
          setRefresh={setRefreshReserv}
        />
      ) : (
        <>
          <div
            className=" flex lg:flex-row flex-col  border-2 rounded-lg  w-full "
            key={reserv["ReservationID"]}
          >
            <div className="flex ">
              <div
                className="flex justify-self-center self-center p-3 flex items-center"
                data-hour={hour}
              >
                <FiClock className="mx-2" />
                <span className="align-middle pl-1 mb-0 font-bold text-xl">
                  {hour}
                </span>
              </div>
              <div className="flex  self-center flex items-center">
                <FiUser className="mx-2" />
                {reserv["NumPeople"]}
              </div>
            </div>

            <div className=" grow justify-self-center   self-center p-2">
              <span>
                {reserv["Name"]},{reserv["Surname"]}
              </span>
            </div>

            <div className=" justify-self-end lg:self-start self-end p-1">
              <button
                onClick={(event) => handleExtra(event)}
                id={reserv["ReservationID"]}
              >
                <FiPlusCircle
                  className="mx-2"
                  style={reserv["Comments"] && { color: "red " }}
                />
              </button>
              <button
                onClick={() =>
                  handleEdit(
                    date,
                    hour,
                    reserv["ReservationID"],
                    reserv["Name"],
                    reserv["Surname"],
                    reserv["NumPeople"],
                    reserv["Phone"],
                    reserv["Comments"]
                  )
                }
                id={reserv["ReservationID"]}
              >
                <FiEdit className="mx-2" />
              </button>
              <button onClick={() => setMsg(true)} id={reserv["ReservationID"]}>
                <FiTrash2 className="mx-2" />
              </button>
            </div>
            <div className="hidden">
              <div className="self-center flex items-center">
                <FiPhone className="mx-2 w-8 " /> {reserv["Phone"]}
              </div>
              <div className=" self-center flex items-center">
                <FiMail className="mx-2 w-8" />
                {reserv["Email"]}
              </div>
              {reserv["Comments"] && (
                <div className="  self-center flex items-center  ">
                  <FiMessageCircle className="mx-2 w-8 " />
                  <span> {reserv["Comments"]} </span>
                </div>
              )}
            </div>
          </div>

          {msg && (
            <div className=" flex px-4 p-2 border-yellow-600 font-bold text-slate-800 rounded-lg text-sm bg-slate-300 justify-between ">
              <span>确定删除？</span>
              <div className="flex gap-8">
                <button
                  className="text-green-600"
                  onClick={() => handleConfirmDelete()}
                >
                  确定
                </button>
                <button className="text-red-700" onClick={() => setMsg(false)}>
                  取消
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default IndividualReservation;

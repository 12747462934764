import axios from "axios";

const deploy = process.env.NODE_ENV;
var url;
if (deploy === "production") {
  url = "https://shulerhotpot.com/api";
}
else {
  url = "http://localhost:8080/api"
}

const api = axios.create({
  baseURL: url,
});
api.defaults.withCredentials = true;

export const fetchReservations = async (date) => {
  try {
    const response = await api.get("/admin/fetchReservations/" + date.getTime()
    );

    return await response.data;
  }
  catch (err) {
    throw err;
  }

};

export const modifyHour = async (day, time, status) => {
  try {
    const result = await api.post("/admin/modifyHour", {
      day: day,
      time: time,
      status: status
    });
    return result.data;
  } catch (err) {
    throw err;
  }
};


export const askPermission = async () => {
  try {
    const response = await api.request("/admin/permission");
    return response.status;
  }
  catch (err) {
    throw err;
  }

};


import { useNavigate, useLocation } from "react-router-dom";
function Confirmation() {
  // let navigate = useNavigate();
  let location = useLocation();

  return (
    <div className=" lg:w-4/5 lg:w-3/4 pt-4  place-self-center">
      <h2 className="font-bold text-xl p-4">RESERVA CONFIRMADA</h2>
      <p className="p-4 leading-loose">
        Su código de reserva es: <b>{location.state.Id}</b> . En caso de
        necesitar modificar la fecha o el número de personas, acceda a nuestro
        apartado de{" "}
        <a href="https://shulerhotpot.com/modifyReservation">
          Modifica mi reserva
        </a>{" "}
        dento de Reservas o simplemente, llámenos. <br />
        <br />
        Toda esta información se la hemos enviado al correo proporcionado.
        <br /> <br />
        Esperamos verle pronto!
      </p>
      {/* <span> </span>
      <button
        onClick={() => {
          navigate("/");
        }}
      >
        HOME
      </button>
      <button
        onClick={() => {
          navigate("/menu");
        }}
      >
        MENU
      </button> */}
    </div>
  );
}

export default Confirmation;

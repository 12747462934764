import axios from "axios";
const deploy = process.env.NODE_ENV;
var url;
if (deploy === "production") {
  url = "https://shulerhotpot.com/api";
} else {
  url = "http://localhost:8080/api";
}
// console.log("here in apiClient.js",url);
const api = axios.create({
  baseURL: url,
});
api.defaults.withCredentials = true;

export const sendForm = async (formData) => {
  return await api.post("/form", formData);
};

export const askTime = async (date, time) => {
  // console.log(date.getTime(), time);
  const response = await api.post("/calendar", {
    date: date.getTime(),
    time: time,
  });
  const timeObjectArray = response.data.times.map((time) => new Date(time));

  return timeObjectArray;
};

export const sendLogin = async (formData) => {
  try {
    const result = await api.post("/login", {
      username: formData.username,
      pwd: formData.pwd,
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const verifyReservation = async (formData) => {
  return await api.post("/verifyReservation", formData);
};

export const modifyReservation = async (formData) => {
  return await api.post("/modifyReservation", formData);
};

export const deleteReservation = async (id, day, time) => {
  const res = await api.post("/deleteReservation", {
    reservationID: id,
    day,
    time,
  });
  //console.log(res);
  return res;
};

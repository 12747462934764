import React from "react";
import { FiAlertTriangle, FiCheckCircle } from "react-icons/fi";
import { useLocation } from "react-router-dom";
function ControlMsg() {
  let location = useLocation();

  return (
    <div
      className={
        location.state.status === "OK"
          ? "lg:w-1/2 lg:h-1/2 p-3 m-auto flex justify-center border-2 border-green-300 text-green-600 rounded-xl gap-2  items-center"
          : "lg:w-1/2 lg:h-1/2 p-3 m-auto flex justify-center border-2 border-red-300 text-red-600 rounded-xl gap-2 items-center"
      }
    >
      {location.state.status === "OK" ? <FiCheckCircle /> : <FiAlertTriangle />}

      <span>{location.state.msg}</span>
    </div>
  );
}
export default ControlMsg;

import React from "react";

import ReservationForm from "./reservationForm";
function Reservation() {
  return (
    <div className=" w-4/5 lg:w-3/4 pt-4 place-self-center ">
      <ReservationForm />
    </div>
  );
}

export default Reservation;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import myLogo from "./img/shuler_logo.png";
import Menu from "./img/Menu.png";
import Reservation from "./img/Reservation.png";
import Contact from "./img/Contact.png";

const categories = [
  ["Menu", "Menu", <img src={Menu} className="h-6" />],
  ["Reservas", "Reservation", <img src={Reservation} className="h-6" />],
  ["Contacto", "Contact", <img src={Contact} className="h-6" />],
];
function Navigation() {
  const [dropdown, setDropdown] = useState(false);

  return (
    <>
      <nav className="border-b-0 flex flex-col justify-center min-h-32 shadow-slate-200 shadow-md">
        <div className="flex justify-between flex-row h-20">
          <Link
            to="/"
            className="flex items-center text-decoration-none min-w-1/2 text-black lg:ml-14 ml-4"
          >
            <img src={myLogo} className="mr-3 h-12" />
            <span className="self-center font-custom font-medium text-xl font-black">
              HOTPOT <br />
              SHULER
            </span>
          </Link>
          <button
            className="sm:hidden mr-8 min-w-1/2 space-y-2"
            onClick={() => setDropdown(!dropdown)}
          >
            <span className="block w-7 h-0.5 bg-slate-900"></span>
            <span className="block w-7 h-0.5 bg-slate-900"></span>
            <span className="block w-7 h-0.5 bg-slate-900"></span>
          </button>
          <div className="hidden sm:flex flex-row justify-center lg:mr-10 mr-0">
            {categories.map((item) => (
              <Link
                key={`/${item[1]}`}
                to={`/${item[1]}`}
                className="flex align-middle items-center lg:mr-8 mr-4 text-slate-900 hover:text-slate-500 text-decoration-none"
              >
                {item[2]}
                <p className="ml-2 text-md my-auto font-medium">{item[0]}</p>
              </Link>
            ))}
          </div>
        </div>
        {dropdown && (
          <div className="w-full sm:hidden">
            {categories.map((item) => (
              <Link
                to={`/${item[1]}`}
                reloadDocument={true}
                className="flex text-decoration-none block py-2.5 pr-4 pl-4 text-black border-t-2 sm:p-0"
              >
                {item[2]}
                <p className="font-mono ml-2 text-md my-auto font-medium">
                  {item[0]}
                </p>
              </Link>
            ))}
          </div>
        )}
      </nav>
    </>
  );
}

export default Navigation;

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import "./../css/datepicker.css";
import "./../js/datepicker.js";
import { askTime, simulateTime } from "../api/apiClient";
import es from "date-fns/locale/es";

const deleteTimesInHoliday = (availableTimes) => {
  availableTimes = availableTimes.map((time) => {
    if (time.getDay() != 1 && time.getDay() != 2) {
      return time;
    } else if (time.getHours() > 18) {
      return time;
    }
  });
  return availableTimes;
};
function Calendar({
  setStartTime,
  setStartDate,
  startDate,
  startTime,
  autoSetHour,
  modifyHour,
}) {
  const [timingAvailable, setTimingAvailable] = useState([]);
  const [empty, setEmpty] = useState(false);
  // everytime a date is chosen, it is looked up in the DB
  useEffect(async () => {
    var availableIntervals;
    if (modifyHour) {
      //ask for simulation
      // console.log("with start time");
      availableIntervals = await askTime(startDate, startTime);
    } else {
      // console.log("without start time", startDate.getDay());
      availableIntervals = await askTime(startDate, -1);
      availableIntervals = await deleteTimesInHoliday(availableIntervals);
      // console.log("availableIntervals", availableIntervals);
    }

    if (availableIntervals.length === 0) {
      setEmpty(true);
      setStartTime(-1);
    } else {
      setEmpty(false);
      setTimingAvailable(availableIntervals);
    }

    if (availableIntervals && autoSetHour) {
      setStartTime(availableIntervals[0]);
    }
  }, [startDate]);

  return (
    <div>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        minDate={new Date()}
        maxDate={addDays(new Date(), 14)}
        locale={es}
        withPortal
        dateFormat="d MMMM yyyy  "
        fixedHeight
      />
      {empty ? (
        <div className="text-center">No hay horas disponibles</div>
      ) : (
        <DatePicker
          includeTimes={timingAvailable}
          selected={startTime}
          onChange={(date) => setStartTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="time"
          inline
          timeFormat="HH:mm"
        />
      )}
    </div>
  );
}
export default Calendar;

import { Outlet } from "react-router";
import Footer from "./footer";
import Navigation from "./navbar";
import { useState } from "react";

function Layout() {
  const [itemsClient] = useState([
    { id: 0, value: "Home", link: "/" },
    { id: 1, value: "Menu", link: "/menu" },
    { id: 2, value: "Reservations", link: "/reservation" },
    { id: 3, value: "Contact", link: "/contact" },
  ]);

  return (
    <div className="min-h-screen flex flex-col">
      <Navigation elements={itemsClient}></Navigation>
      <div className="d-block grid  grow">
        <Outlet></Outlet>
      </div>

      <Footer className="flex flex-col content-end" />
    </div>
  );
}
export default Layout;

import React from "react";
import "./App.css";
import Home from "./components/home.jsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Menu from "./components/menu.jsx";
import Contact from "./components/contact";
import Reservation from "./components/reservation";
import AdminMain from "./components/admin/adminMain";
import Confirmation from "./components/confirmation";
import Login from "./components/login";
import Layout from "./components/layout";
import ModifyReservation from "./components/modifyReservation";
import ControlMsg from "./components/controlMsg";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/admin" element={<AdminMain />}></Route>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/reservation" element={<Reservation />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/login" element={<Login />} />
            <Route path="/modifyReservation" element={<ModifyReservation />} />
            <Route path="/controlMsg" element={<ControlMsg />} />
          </Route>

        </Routes>

      </BrowserRouter>
    </>
  );
}

export default App;

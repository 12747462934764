import React, { useState } from "react";
import Carousel1 from "./imgCarousel";
import a from "./img/pic1.jpg";
import b from "./img/pic2.jpg";

import d from "./img/pic4.jpg";
import e from "./img/pic5.jpg";

import general_description from "./img/general_description.jpg";
import intro1 from "./img/descriptive_pdf_content/intro1.png";
import filosophy2 from "./img/descriptive_pdf_content/filosophy2.png";
import description3 from "./img/descriptive_pdf_content/description3.png";
import purpose4 from "./img/descriptive_pdf_content/purpose4.png";
import aim5 from "./img/descriptive_pdf_content/aim5.png";
import future6 from "./img/descriptive_pdf_content/future6.png";
import moral7 from "./img/descriptive_pdf_content/moral7.png";
import identity8 from "./img/descriptive_pdf_content/identity8.png";
import msg9 from "./img/descriptive_pdf_content/msg9.png";
import us10 from "./img/descriptive_pdf_content/us10.png";
import online11 from "./img/descriptive_pdf_content/online11.png";
import social12 from "./img/descriptive_pdf_content/social12.png";
import insta13 from "./img/descriptive_pdf_content/insta13.png";

import video1 from "./video/video_home.mp4";

function Home() {
  const [imgLegend] = useState([
    {
      img: d,
      description:
        "Hotpot + familia/amigos = delicia + felicidad = felicidad x2 ",
    },
    { img: a, description: "Rollos de cerdo ibérico" },
    {
      img: b,
      description: "Para gustos,los colores. Para comida, Hotpot Shuler! ",
    },
    { img: e, description: "Crispy pork" },
  ]);
  const images = [
    { src: intro1, key: "intro1" },
    { src: filosophy2, key: "filosophy" },
    { src: description3, key: "description" },
    { src: purpose4, key: "purpose" },
    { src: aim5, key: "aim" },
    { src: future6, key: "future" },
    { src: moral7, key: "moral" },
    { src: identity8, key: "identity" },
    { src: msg9, key: "msg" },
    { src: us10, key: "us" },
    { src: online11, key: "online" },
    { src: social12, key: "social" },
    { src: insta13, key: "insta" },
  ];
  return (
    <div className="flex flex-col h-full gap-9  ">
      <div className="block justify-center align-center shrink  max-w-fit  place-self-center ">
        {/* block justify-center align-center shrink  h-full  place-self-center */}
        <video
          playsInline="true"
          autoplay="autoplay"
          loop="true"
          muted
          class="  "
        >
          {/* h-screen  */}
          <source src={video1} type="video/mp4 "></source>
        </video>
      </div>
      <div className="flex flex-col  h-full">
        {images.map((image) => (
          <img
            className="p-5 lg:m-auto lg:h-full w-full"
            src={image.src}
            key={image.key}
          />
        ))}

        {/* <div className="flex  lg:flex-row flex-col">
          <div>
            <img
              className="p-5 lg:m-auto lg:h-full w-full"
              src={general_description}
              key="general_description"
            />
          </div>
          <div className=" pl-8  pr-8  lg:w-3/5 m-auto">
            <p className="leading-10 text-justify tracking-wider">
              <b className="text-xl">
                ¿Cansado de que la comida china solo sea arroz tres delicias y
                pollo con almendras?
              </b>
              <br />
              Ven a conocer un poco más de la cocina oriental: el Hotpot,o 火锅
              huoguo en chino, este es un popular método de cocina en la
              gastronomía China.
              <br />
            </p>
            <br />
            <br />
            <b>¿Qué tiene de especial?</b>
            <ol className="leading-10 list-decimal list-inside text-justify tracking-wider">
              <li>
                El momento de compartir este delicioso manjar con los que más
                quieres.
              </li>
              <li>
                El no tener que acordar para pedir algo que les tenga que gustar
                a TODOS, TODOS, TODOS, bien sabemos que eso siempre es
                complicado; aquí cada uno pide lo que más le gusta y lo cocina
                él mismo en esta olla común (y para los que no cocineis a
                menudo, no os preocupeis, os indicaremos el tiempo de cocción
                adecuado para cada alimento).
              </li>
              <li>
                Y lo mejor de todo, es que cada uno se podrá personalizar la
                salsa con la que acompañar las delcias escogidas.
              </li>
              <li>
                Ay! Otro punto positivo, por supuesto, es que ... NO HAY QUE
                LAVAR LOS PLATOS! (todos lo odiamos, hay que admitirlo)
              </li>
            </ol>
            <small>
              PD: Para los que a ratos no soporteis a esos amigos pesados, o
              querrais tener una comida tranquila solos, también tenemos ollas
              para una única persona!{" "}
            </small>
          </div>
        </div>

        <div className="">
          <Carousel1 imgArray={imgLegend} />
        </div> */}
      </div>
    </div>
  );
}

export default Home;

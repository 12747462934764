import React from "react";
import myLogo from "./img/shuler_logo.png";
import phoneIcon from "./img/telephone.png";
import mapIcon from "./img/placeholder.png";
import instagramIcon from "./img/instagram.png";
function Footer() {
  return (
    <footer className=" footer flex flex-col p-4 sm:p-6 justify-between shadow-slate-400 shadow-2xl sm:flex-row">
      <div className="flex flex-col">
        <div className="flex sm:justify-start justify-center items-center ">
          <img src={mapIcon} alt="Ubicacion" className="h-4 mr-2" />
          <span className="flex ml-0 pl-0 text-center">
            Gran Via Corts Catalanes, 692
            <p className="hidden sm:block"> (Barcelona) </p>
          </span>
        </div>
        <p className="text-center block sm:hidden"> (Barcelona)</p>
        <div className="flex items-center sm:justify-start justify-center">
          <img src={phoneIcon} alt="Telefono" className="h-4 mr-2" />
          <span> 930 31 64 02 </span>
        </div>
        <div className="flex sm:justify-start justify-center items-center">
          <img src={instagramIcon} alt="Instagram" className="h-4 mr-2" />
          <span> @slx_hot_pot </span>
        </div>
      </div>
      <div className="flex flex-row justify-center sm:my-auto mt-4 items-center">
        <img src={myLogo} className="mr-3 h-12" />
      </div>
    </footer>
  );
}
export default Footer;

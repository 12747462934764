import {
  modifyReservation,
  verifyReservation,
  deleteReservation,
} from "./../api/apiClient";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FiCalendar,
  FiSend,
  FiAlertTriangle,
  FiCheckCircle,
  FiTrash,
} from "react-icons/fi";
import Calendar from "./datePicker";
const options = { year: "numeric", month: "2-digit", day: "2-digit" };
const optionTime = { hour: "2-digit", minute: "2-digit" };

function ModifyReservation() {
  const [reservationFound, setReservationFound] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgFlag, setMsgFlag] = useState(false);
  const [data, setData] = useState();
  const [time, setTime] = useState();
  const [date, setDate] = useState();
  const [deleteMsgFlag, setDeleteMsgFlag] = useState(false);
  let navigate = useNavigate();
  const handleVerification = async (event) => {
    event.preventDefault();
    try {
      const res = await verifyReservation(data);

      setData((prevState) => ({
        ...prevState,
        ...res.data,
      }));
      // console.log(res);
      const dateHelper = res.data["DateReservation"].split("/");
      const timeHelper = res.data["HourReservation"].split(":");
      setDate(
        new Date(dateHelper[0], dateHelper[1] - 1, dateHelper[2], 0, 0, 0)
      );
      setTime(
        new Date(
          dateHelper[0],
          dateHelper[1] - 1,
          dateHelper[2],
          timeHelper[0],
          timeHelper[1],
          0
        )
      );
      setReservationFound(true);
    } catch (err) {
      setMsgFlag(true);
      console.log(err.response);
      setMsg({ status: "FAIL", msg: err.response.data });
      navigate("/controlMsg", {
        state: { status: "FAIL", msg: err.response.data },
      });
    }
  };
  const handleChange = (event) => {
    setMsgFlag(false);
    setData((previousState) => ({
      ...previousState,
      [event.target.name]: event.target.value,
    }));
  };
  const handleModifyReservation = async (event) => {
    setMsgFlag(false);
    setMsg({});
    // setData((data.newDate = date.toLocaleDateString("zh-CN")));
    // setData((data.newTime = time.toLocaleTimeString("sp-SP").slice(0, 5)));
    event.preventDefault();
    try {
      await modifyReservation({
        ...data,
        newDate: date.toLocaleDateString("zh-CN", options),
        newTime: time.toLocaleTimeString("es-SP", optionTime),
      });

      setMsgFlag(true);
      setMsg({ status: "OK", msg: "Cambios guardados correctamente" });
      navigate("/controlMsg", {
        state: {
          status: "OK",
          msg: "Cambios guardados correctamente",
        },
      });

      setData((previousState) => ({
        ...previousState,
        ["DateReservation"]: date.toLocaleDateString("zh-CN", options),
        ["HourReservation"]: time.toLocaleTimeString("es-SP", optionTime),
      }));
    } catch (err) {
      setMsgFlag(true);
      setMsg({
        status: "FAIL",
        msg: "Ups, ha habido un fallo, recargue la página y vuelva a intentarlo. Disculpe las molestias",
      });
      navigate("/controlMsg", {
        state: {
          status: "FAIL",
          msg: "Ups, ha habido un fallo, recargue la página y vuelva a intentarlo. Disculpe las molestias",
        },
      });
    }
  };

  const handleDelete = async () => {
    // console.log(data["DateReservation"], data["HourReservation"]);
    const res = await deleteReservation(
      data["ReservationID"],
      data["DateReservation"],
      data["HourReservation"]
    );
    // console.log(res);
  };
  return (
    <div className="w-4/5 lg:w-3/4 pt-4  self-start justify-self-center ">
      <div className={deleteMsgFlag && " hidden"}>
        <div className="flex justify-start p-4 gap-4 ">
          <FiCalendar className="w-6 h-6" />
          <span className="font-bold text-xl ">Mi reserva</span>
        </div>

        {reservationFound ? (
          <div className=" flex flex-col border-2 p-7 ">
            <div className="self-end pb-4">
              <button
                className="text-red-500 flex flex-row gap-2  items-center text-cente"
                onClick={() => {
                  setDeleteMsgFlag(true);
                  setMsgFlag(false);
                }}
              >
                <FiTrash />
                Cancelar reserva
              </button>
            </div>

            <form
              className="flex flex-col gap-8 "
              onSubmit={(event) => handleModifyReservation(event)}
            >
              <div className="flex  flex-col lg:flex-row gap-8">
                <div className=" flex flex-col lg:flex-row gap-4 lg:w-1/2 lg:items-center">
                  <label forhtml="ReservationID">
                    Identificador de reserva
                  </label>
                  <input
                    className="border-2 p-1 rounded-xl grow "
                    type="text"
                    name="ReservationID"
                    value={data["ReservationID"]}
                    readOnly
                  />
                </div>
                <div className="flex flex-col lg:flex-row gap-4 lg:w-1/2 lg:items-center">
                  <label forhtml="Email">Correo electrónico</label>
                  <input
                    className="border-2 p-1 rounded-xl grow "
                    type="email"
                    name="Email"
                    value={data["Email"]}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex  flex-col lg:flex-row gap-8 pb-8 border-b-2">
                <div className="flex flex-col lg:flex-row gap-4 lg:w-1/2 lg:items-center">
                  <label forhtml="Name">Nombre</label>
                  <input
                    className="border-2 p-1 rounded-xl grow "
                    type="text"
                    name="Name"
                    value={data["Name"]}
                    readOnly
                  />
                </div>
                <div className="flex flex-col lg:flex-row gap-4 lg:w-1/2 lg:items-center">
                  <label forhtml="Surname">Apellidos</label>
                  <input
                    className="border-2 p-1 rounded-xl grow "
                    type="text"
                    name="Surname"
                    value={data["Surname"]}
                    readOnly
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row lg:gap-8">
                <div className=" flex flex-col gap-4 lg:gap-2 lg:w-1/4">
                  <label>Dia y hora </label>{" "}
                  <Calendar
                    startDate={date}
                    startTime={time}
                    setStartDate={(date) => setDate(date)}
                    setStartTime={(date) => setTime(date)}
                    autoSetHour={false}
                    modifyHour={true}
                  />
                </div>
                <div className="flex flex-col lg:w-3/4 gap-8 lg:justify-around">
                  <div className="flex flex-col lg:flex-row gap-8  ">
                    <div className="flex   flex-col lg:flex-row lg:w-1/2 gap-4 lg:items-center">
                      <label forhtml="Phone">Teléfono</label>
                      <input
                        className="border-2 p-1 rounded-xl grow "
                        type="number"
                        name="Phone"
                        onChange={(event) => handleChange(event)}
                        value={data["Phone"]}
                        required
                      />
                    </div>
                    <div className="flex  flex-col lg:flex-row lg:w-1/2 gap-4 lg:items-center">
                      <label forhtml="NumPeople">Numero de personas</label>
                      <input
                        className="border-2 p-1 rounded-xl grow "
                        type="number"
                        name="NumPeople"
                        onChange={(event) => handleChange(event)}
                        value={data["NumPeople"]}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex  flex-col gap-4">
                    <label forhtml="Comments">Observaciones</label>
                    <textarea
                      className="border-2 p-1 rounded-xl "
                      type="text"
                      rows="3"
                      name="Comments"
                      maxLength={80}
                      onChange={(event) => handleChange(event)}
                      value={data["Comments"]}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button className="flex gap-2 items-center" type="submit">
                  <FiSend />
                  GUARDAR CAMBIOS
                </button>
              </div>
            </form>
          </div>
        ) : (
          <>
            <form className="flex flex-col border-2 p-7 gap-8 rounded-xl">
              <div className="flex  flex-col lg:flex-row gap-8">
                <div className=" flex flex-col lg:flex-row gap-4 lg:w-1/2 lg:items-center">
                  <label forhtml="ReservationID">
                    Identificador de reserva
                  </label>
                  <input
                    className="border-2 p-1 rounded-xl grow "
                    type="text"
                    name="ReservationID"
                    onChange={(event) => handleChange(event)}
                    required
                  />
                </div>
                <div className="flex flex-col lg:flex-row gap-4 lg:w-1/2 lg:items-center">
                  <label forhtml="Email">Correo electrónico</label>
                  <input
                    className="border-2 p-1 rounded-xl grow "
                    type="email"
                    name="Email"
                    onChange={(event) => handleChange(event)}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  className="flex gap-2 items-center"
                  type="buttton"
                  onClick={handleVerification}
                >
                  <FiSend />
                  ENVIAR
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      {deleteMsgFlag && (
        <div className=" flex flex-col  gap-9 p-9  font-bold self-center text-slate-800 rounded-xl bg-purple-50 justify-between">
          <span className="text-lg">
            ¿Seguro que quiere eliminar la reserva?
          </span>
          <div className="flex self-end gap-8">
            <button
              className="text-green-400 text-md border-2 border-green-400 p-2 px-3 rounded-xl"
              onClick={async () => {
                try {
                  await handleDelete();
                  setMsgFlag(true);
                  setMsg({
                    status: "OK",
                    msg: "Reserva borrada correctamente",
                  });
                  navigate("/controlMsg", {
                    state: {
                      status: "OK",
                      msg: "Reserva borrada correctamente",
                    },
                  });
                } catch (err) {
                  setMsgFlag(true);
                  setMsg({ status: "FAIL", msg: err.response.data });
                  navigate("/controlMsg", {
                    state: {
                      status: "FAIL",
                      msg: err.response.data,
                    },
                  });
                }
              }}
            >
              SI
            </button>
            <button
              className="text-red-500 text-md  border-2 border-red-500 p-2 rounded-xl"
              onClick={() => {
                setDeleteMsgFlag(false);
              }}
            >
              NO
            </button>
          </div>
        </div>
      )}
      {msgFlag && (
        <div
          className={
            msg.status === "OK"
              ? "p-3 flex justify-end border-2 border-green-300 text-green-600 rounded-b-xl gap-2  items-center"
              : "p-3 flex justify-end border-2 border-red-300 text-red-600 rounded-b-xl gap-2 items-center"
          }
        >
          {msg.status === "OK" ? <FiCheckCircle /> : <FiAlertTriangle />}
          <span>{msg.msg}</span>
        </div>
      )}
    </div>
  );
}
export default ModifyReservation;
